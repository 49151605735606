import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, InputAdornment, IconButton, Snackbar, Alert, MenuItem } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { auth, provider } from '../firebaseConfig';
import { signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import TermsDialog from './TermsDialog';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

function RegisterForm({ switchToLogin, setUserData }) {
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
  const [disabledFields, setDisabledFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const [showPasswordFields, setShowPasswordFields] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    psychologyField: '',
  });
  const [countryCode, setCountryCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const psychologyFields = [
    'Psicoanálisis',
    'Conductismo',
    'Humanismo',
    'Cognitivo-Conductual',
    'Gestalt',
    'Sistémica',
    'Otra',
    'No soy psicólogo',
  ];

  const { setIdentity } = useTrackingCode();

  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        setCountryCode(response.data.country_code.toLowerCase());
      } catch (error) {
        console.error('Error al obtener la IP y país:', error);
      }
    };
    getCountry();
  }, []);

  useEffect(() => {
    if (formData.email) {
      setIdentity(formData.email);
    }
  }, [formData.email, setIdentity]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleGoogleRegister = async () => {
    setLoading(true);
    setError('');
  
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      const [firstName, lastName] = user.displayName.split(' ');
      const email = user.email;
  
      setFormData({
        firstName,
        lastName,
        email,
        phone: '',
        birthDate: '',
        psychologyField: '',
      });
  
      setDisabledFields({ firstName: true, lastName: true, email: true });
      setShowPasswordFields(false);
      setIsGoogleSignIn(true);
  
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hubspot/contact`, {
        email,
        firstName,
        lastName,
        source: 'registro',
      });
  
      setLoading(false);
    } catch (error) {
      setError(`Error al iniciar sesión con Google: ${error.message}`);
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

const handleSubmitGoogleData = async () => {
  if (!formData.phone || !formData.birthDate) {
    setError('Todos los campos son obligatorios');
    setOpenSnackbar(true);
    return;
  }

  setLoading(true);

  try {
    const user = auth.currentUser;
    const idPsico = user.uid;

    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/psico/register`, {
      idPsico,
      fullname: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      birthdate: formData.birthDate,
      phone: formData.phone,
      plan: 'free',
    });

    // Formatear birthDate a 'YYYY-MM-DD' para HubSpot
    const birthDateFormatted = new Date(formData.birthDate).toISOString().split('T')[0];

    // Enviar datos completos a HubSpot
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hubspot/contact`, {
      email: formData.email,
      phone: formData.phone,
      firstName: formData.firstName,
      lastName: formData.lastName,
      birthDate: birthDateFormatted,
      psychologyField: formData.psychologyField,
      source: 'registro',
    });

    localStorage.setItem('idPsico', idPsico);
    setUserData(formData);

    setShowTerms(true);
    setLoading(false);
  } catch (error) {
    setError(`Error al completar el registro: ${error.message}`);
    setOpenSnackbar(true);
    setLoading(false);
  }
};

  const handleTermsAcceptGoogle = () => {
    setTermsAccepted(true);
    setShowTerms(false);

    navigate('/psicologo');
  };

  const handleEmailRegister = async () => {
    if (!formData.birthDate || !formData.phone || !formData.password || !formData.confirmPassword) {
      setError('Todos los campos son obligatorios');
      setOpenSnackbar(true);
      return;
    }
  
    if (formData.password !== formData.confirmPassword) {
      setError('Las contraseñas no coinciden');
      setOpenSnackbar(true);
      return;
    }
  
    setLoading(true);
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
  
      const idPsico = user.uid;
      const fullname = `${formData.firstName} ${formData.lastName}`;
  
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/psico/register`, {
        idPsico,
        fullname,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        birthdate: formData.birthDate,
        phone: formData.phone,
        plan: 'free',
      });
  
      const birthDateFormatted = new Date(formData.birthDate).toISOString().split('T')[0];

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hubspot/contact`, {
        email: formData.email,
        phone: formData.phone,
        firstName: formData.firstName,
        lastName: formData.lastName,
        birthDate: birthDateFormatted,
        psychologyField: formData.psychologyField,
        source: 'registro',
      });

      localStorage.setItem('idPsico', idPsico);
      setUserData(formData);
  
      setShowTerms(true);
    } catch (error) {
      setError(`Error al registrar: ${error.message}`);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneChange = (phone) => {
    setFormData({
      ...formData,
      phone: phone,
    });
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box component="form">
      <Box
        component="img"
        sx={{
          height: 60,
          mb: 2,
        }}
        alt="Logo"
        src="/download-1@2x.png"
      />
      <TextField
        label="Nombre"
        name="firstName"
        value={formData.firstName}
        onChange={handleInputChange}
        fullWidth
        disabled={isGoogleSignIn}
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Apellidos"
        name="lastName"
        value={formData.lastName}
        onChange={handleInputChange}
        fullWidth
        disabled={isGoogleSignIn}
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Fecha de Nacimiento"
        name="birthDate"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={formData.birthDate}
        onChange={handleInputChange}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Correo electrónico"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        fullWidth
        disabled={isGoogleSignIn}
        sx={{ marginBottom: 2 }}
      />
      <PhoneInput
        country={countryCode}
        value={formData.phone}
        onChange={handlePhoneChange}
        inputProps={{
          name: 'phone',
          required: true,
        }}
        inputStyle={{
          width: '100%',
          height: '56px',
          fontSize: '16px',
          paddingLeft: '48px',
          borderRadius: '4px',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          backgroundColor: 'transparent',
        }}
        containerStyle={{
          marginBottom: '16px',
        }}
        placeholder="Teléfono"
      />
      <TextField
        select
        label="Corriente de psicología"
        name="psychologyField"
        value={formData.psychologyField}
        onChange={handleInputChange}
        fullWidth
        sx={{ marginBottom: 2 }}
      >
        {psychologyFields.map((field) => (
          <MenuItem key={field} value={field}>
            {field}
          </MenuItem>
        ))}
      </TextField>
      {showPasswordFields && (
        <>
          <TextField
            label="Contraseña"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Confirmar contraseña"
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            value={formData.confirmPassword}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
        </>
      )}

      {disabledFields.firstName ? (
        <Button
          onClick={handleSubmitGoogleData}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled={loading}
        >
          {loading ? 'Guardando datos...' : 'Completar mi registro'}
        </Button>
      ) : (
        <>
          <Button
            onClick={handleEmailRegister}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled={loading}
          >
            {loading ? 'Registrando...' : 'Registrarse con Correo'}
          </Button>

          <Button
            onClick={handleGoogleRegister}
            variant="contained"
            fullWidth
            startIcon={<GoogleIcon />}
            sx={{
              backgroundColor: '#4285F4',
              color: 'white',
              '&:hover': {
                backgroundColor: '#357ae8',
              },
              marginBottom: 2,
            }}
            disabled={loading}
          >
            {loading ? 'Registrando...' : 'Registrarse con Google'}
          </Button>

          <Button onClick={() => switchToLogin()} fullWidth>
            ¿Ya tienes cuenta? Inicia Sesión
          </Button>
        </>
      )}

      <TermsDialog
        showTerms={showTerms}
        handleTermsClose={() => setShowTerms(false)}
        handleTermsAccept={handleTermsAcceptGoogle}
        userData={formData}
      />

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default RegisterForm;